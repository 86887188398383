<template>
  <div id="cya">
    <MenuHome @mousemove="handleMouseMove"/>

    <div class="contenido" :data-componente="currentComponentName">
      <router-view v-slot="{ Component }">
        <keep-alive>
          <component :is="Component" :class="{ 'home--moving': isHomeMoving }"/>
        </keep-alive>
      </router-view>
    </div>
  </div>
</template>

<script setup>
import MenuHome from "@/components/Menu/MenuHome.vue";
import {ref, computed, onMounted, onBeforeUnmount} from 'vue';
import {useStore} from 'vuex';

const store = useStore();
const currentComponentName = computed(() => store.getters.getCurrentComponentName);
const timeoutId = ref(null);
const isHomeMoving = ref(false);

onMounted(() => {
  designBy();
});

onBeforeUnmount(() => {
  // Limpiar el temporizador si el componente se desmonta
  if (timeoutId.value) {
    clearTimeout(timeoutId.value);
  }
});

const designBy = () => {
  console.log("%c Project by Alberto GC", "background: #4a14ed; color: #fff;");
};

const handleMouseMove = () => {
  if (currentComponentName.value === 'HomeComponent') {
    if (timeoutId.value) {
      clearTimeout(timeoutId.value);
    }

    document.documentElement.classList.add('home--moving');

    timeoutId.value = setTimeout(() => {
      document.documentElement.classList.remove('home--moving');
      timeoutId.value = null;
    }, 2000);
  }
};
</script>

<style>
@import url('https://fonts.cdnfonts.com/css/neue-haas-grotesk-display-pro');

html, body {
  touch-action: manipulation;
}

html {
  background: #000;
  box-sizing: border-box;
  color: #fff;
  font-size: .52083vw;
  height: 100%;
  left: 0;
  overflow: hidden;
  -ms-scroll-chaining: none;
  overscroll-behavior: none;
  position: fixed;
  -moz-osx-font-smoothing: grayscale;
  top: 0;
  -webkit-font-smoothing: antialiased;
  width: 100%
}

@media (max-width: 768px) {
  html {
    font-size: 1.85185vw
  }
}

body {
  font: 3rem 'Neue Haas Grotesk Display Pro', sans-serif;
  font-weight: 600;
  height: 100%;
  left: 0;
  opacity: 1;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%
}

@media (max-width: 768px) {
  body {
    font-size: 2.5rem
  }
}

a, button {
  color: inherit;
  outline: none
}

a {
  text-decoration: none
}

button {
  background: 0 0;
  border: none;
  border-radius: unset;
  font: inherit
}

ol, ul {
  list-style: none
}

*, :after, :before {
  box-sizing: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  -webkit-touch-callout: none
}

* {
  margin: 0;
  padding: 0
}

a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

.galeria, .galeria-component-media-image, .galeria-modal, .gudu-header-media-image, .gudu-list-item-media-image, .info-media-image, .kirin-header-media-image, .kirin-list-item-media-image, .loader-progress, .music-section-item-media-image, .navigation, .player, .press-covers-item-media-image, .tour-list-item-media-image, .video, .video-box-iframe, .video-modal, .videos-section-video-media {
  height: 100%;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%
}

.galeria-content, .gudu-header-description, .gudu-list-item-title, .kirin-header-description, .kirin-list-item-content-title, .kirin-list-item-description, .music-section-item-title, .navigation-list, .press-covers-item-description, .press-covers-item-title, .press-videos-item-content-title, .press-videos-item-description, .video-content, .videos-section-video-content {
  font-size: 2.4rem
}

@media (max-width: 768px) {
  .galeria-content, .gudu-header-description, .gudu-list-item-title, .kirin-header-description, .kirin-list-item-content-title, .kirin-list-item-description, .music-section-item-title, .navigation-list, .press-covers-item-description, .press-covers-item-title, .press-videos-item-content-title, .press-videos-item-description, .video-content, .videos-section-video-content {
    font-size: 1.75rem
  }
}

.contenido {
  height: 100%;
  left: 0;
  overflow: auto;
  position: absolute;
  top: 0;
  width: 100%
}

.news, .gudu, .home, .info, .galeria, .music, .press, .tour, .videos {
  background: #000;
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity .3s ease, visibility .3s ease;
  visibility: hidden;
  width: 100%
}

.galeria-list-item-description a, .gudu-footer a, .gudu-links-item a, .info-content a, .kirin-links a, .music-links-item a, .press-index-item-description a, .tour-footer a {
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top
}

.home:after, .home:before, .news:after, .news:before, .gudu:after, .gudu:before, .info:after, .info:before, .galeria:after, .galeria:before, .music:after, .music:before, .press:after, .press:before, .tour:after, .tour:before, .videos:after, .videos:before {
  content: "";
  height: 16rem;
  left: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
  z-index: 1
}

/*cambiar nombres segun crees las cosas*/
.home:after, .news:after, .gudu:after, .info:after, .galeria:after, .music:after, .press:after, .tour:after, .videos:after {
  background: linear-gradient(180deg, #000 10%, transparent);
  top: 0;
}

.home:before, .news:before, .gudu:before, .info:before, .galeria:before, .music:before, .press:before, .tour:before, .videos:before {
  background: linear-gradient(0deg, #000 10%, transparent);
  bottom: 0;
  z-index: 2;
}

::-webkit-scrollbar {
  display: none;
}

/* css prime vue */
.p-component {
  font-size: 1.5rem;
}

.p-inputswitch {
  width: 3rem;
  height: 1.75rem;
  margin-top: 2px;
}

.p-inputswitch.p-highlight .p-inputswitch-slider:before {
  transform: translateX(1.50rem);
}

.p-splitbutton .p-splitbutton-defaultbutton, .p-splitbutton.p-button-rounded > .p-splitbutton-defaultbutton.p-button, .p-splitbutton.p-button-outlined > .p-splitbutton-defaultbutton.p-button, .p-splitbutton.p-button-outlined > .p-splitbutton-defaultbutton.p-button-outlined.p-button:hover {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0 none;
}

.p-tieredmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
  color: #71717a;
  margin-right: 0.5rem;
}

.p-button {
  height: 3.5rem;
  width: 12rem;
  font-size: 2rem;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: revert-layer;
  padding: 0.5rem 1rem;
}

.p-button.p-button-icon-only {
  width: 2.5rem;
  padding: 0.5rem 0;
}

.p-tieredmenu {
  padding: 0.25rem 0.25rem 0.25rem 0.25rem !important;
  background: #18181b;
  color: #ffffff;
  border: 1px solid #3f3f46;
  border-radius: 6px;
  min-width: 12.5rem;
}

.p-tieredmenu .p-tieredmenu-root-list {
  outline: 0 none;
}

.p-tieredmenu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.p-tieredmenu .p-menuitem > .p-menuitem-content .p-menuitem-link {
  color: #ffffff;
  padding: 0.5rem 0.75rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.p-toast {
  width: 38rem;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  font-size: 1.5rem;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon.p-icon {
  width: 2rem;
  height: 2rem;
  margin: 0.25rem 0 !important;
}

.p-toast .p-toast-message.p-toast-message-success {
  background: rgba(34, 197, 94, 0.16);
  border: 1px solid rgba(21, 128, 61, 0.36);
  color: #22C55E;
}

.p-toast .p-toast-message {
  margin: 0 0 1rem 0;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  font-size: 2rem;
}

.p-toast .p-toast-message .p-toast-message-content {
  padding: 0.75rem;
  border-width: 1px;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
  margin: 0 0 0 0.5rem;
}

.p-splitbutton {
  padding-top: 3rem;
}

.p-splitbutton.p-button-rounded > .p-splitbutton-defaultbutton {
  border-radius: 0.75rem;
}

.p-dialog {
  border-radius: 12px;
  background: #18181b;
}

@media (max-width: 768px) {
  .galeria-content, .gudu-header-description, .gudu-list-item-title, .kirin-header-description, .kirin-list-item-content-title, .kirin-list-item-description, .music-section-item-title, .navigation-list, .press-covers-item-description, .press-covers-item-title, .press-videos-item-content-title, .press-videos-item-description, .video-content, .videos-section-video-content {
    font-size: 1.75rem
  }

  .p-dialog {
    font-size: 1.2rem;
  }
}

/* */
.p-dialog .border-round {
  border-radius: var(--border-radius) !important;
}

.p-dialog .p-5 {
  padding: 2rem !important;
}

.p-dialog .align-items-center {
  align-items: center !important;
}

.p-dialog .flex-column {
  flex-direction: column !important;
}

.p-dialog .flex {
  display: flex !important;
}

.p-dialog .surface-overlay {
  background-color: var(--surface-overlay) !important;
}

.p-dialog .mt-4 {
  margin-top: 1.5rem !important;
}

.p-dialog .w-8rem {
  width: 8rem !important;
}

.p-dialog .gap-2 {
  gap: 1.5rem !important;
}

.p-dialog .p-button {
  color: #020617 !important;
  background: #a78bfa !important;
  border: 1px solid #a78bfa !important;
}

.p-dialog .p-button.p-button-outlined {
  background-color: transparent !important;
  color: #a78bfa !important;
  border: 1px solid !important;
}

/**/
:root {
  --swiper-navigation-size: 31px !important;
}

@media (max-width: 768px) {
  :root {
    --swiper-navigation-size: 23px !important;
  }
}

.swiper-container-galeria .swiper-button-prev, .swiper-button-next {
  color: #fff !important;
  width: calc((30px) / 44 * 27);
  height: 30px;
}

.swiper-container-galeria .swiper-pagination-bullet {
  background: #fff;
}

.swiper-container-galeria .swiper-pagination-bullet-active {
  background: #fff;
}

/**/
.grecaptcha-badge {
  display: none !important;
}

</style>
