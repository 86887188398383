// store.js
import {createStore} from 'vuex';

const store = createStore({
    state: {
        currentComponentName: null,
        currentUrlWeb: process.env.VUE_APP_URL + '/@assets/',
    },
    mutations: {
        setCurrentComponentName(state, componentName) {
            state.currentComponentName = componentName;
        },
    },
    actions: {
        updateCurrentComponentName({commit}, componentName) {
            commit('setCurrentComponentName', componentName);
        },
    },
    getters: {
        getCurrentComponentName: (state) => state.currentComponentName,

        getCurrentUrlWeb: (state) => state.currentUrlWeb,
    },
});

export default store;
