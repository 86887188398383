<template>
  <div :class="{ 'videos': true, 'video-container': true }">

    <nav class="video-menu">
      <div v-for="(section, index) in typesOfVideos" :key="section.type">
        <button @click="handleSection(index, section.type)"
                :class="{ 'video-menu-button': true, 'video-menu-button--active': activeSection === index }">
          {{ section.type }}
        </button>
        <div v-if="index < typesOfVideos.length - 1" class="video-menu-divider">&nbsp;&nbsp;/&nbsp;&nbsp;</div>
      </div>
    </nav>

    <div class="videos-wrapper" @scroll="handleScroll">
      <div v-for="(section) in typesOfVideos" :key="section.type" :id="section.type"
           class="video-section"
           :class="{ 'video-section--reels': section.type === 'Reels', 'video-section--tv': section.type === 'Sets', 'video-section--music': section.type === 'Music Videos', 'video-hover': hoverSection === section.type}">
        <v-row class="video-line">
          <v-col class="video-col" v-for="(video, cardIndex) in section.videoList" :key="cardIndex" :cols="12"
                 :class="{'v-col-sm-3': section.type === 'Reels', 'v-col-sm-6': section.type === 'Music Videos'}">
            <v-card @mouseover="handleHover(cardIndex, section.type)"
                    @mouseleave="handleLeave()" class="mx-auto my-4" :data-id="video.id"
                    :class="{ 'video-card': true, 'videos-section-video--active': hoverCard === cardIndex && hoverSection === section.type}">
              <button @click="handleVideo(video.id, video.iframe)" v-if="video.enlace === null"
                      class="videos-section-video-button">
                <video loop :autoplay="isMobile" muted playsinline :id="'video-' + cardIndex + section.type"
                       class="videos-section-video-media"
                       :src="currentUrl + video.artwork"></video>
              </button>

              <a v-else class="videos-section-video-link" :href="video.enlace" target="_blank" rel="noopener">
                <video loop :autoplay="isMobile" muted playsinline :id="'video-' + cardIndex + section.type"
                       class="videos-section-video-media" :src="currentUrl + video.artwork"></video>
              </a>
            </v-card>
            <v-card-title class="video-title">{{ video.title }}</v-card-title>
          </v-col>
        </v-row>
      </div>
    </div>

    <div class="video-content">
      <div v-for="(video) in videosList" :key="video.type" class="video"
           :class="{'video--active': videoClick === video.id}" :id="video.id">
        <div v-if="video.iframe !== null" class="video-modal">
          <button class="video-button" @click="handleVideo(-1, video.iframe)">
            ← Atrás
          </button>
          <div class="video-wrapper">
            <div class="video-box">
              <iframe class="video-box-iframe" :class="{'video-box-iframe--active': videoClick === video.id}"
                      :src="video.iframe"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen></iframe>
            </div>
            <div class="video-content">
              <div class="video-content-information">
                <h3 class="video-content-title">{{ video.title }}</h3>
                <p class="video-content-description">{{ getYear(video.date) }}</p>
              </div>
              <div v-if="video.extraInfo" class="video-content-credits">
                <p v-html="video.extraInfo"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import axios from 'axios';
import {useStore} from "vuex";

const store = useStore();
import {computed} from "vue";

let activeSection = ref(0);
const typesOfVideos = ref([]);
const videosList = ref([]);
const hoverSection = ref(-1);
const hoverCard = ref(-1);
const videoClick = ref(-1);
const isMobile = ref(false)

const currentUrl = computed(() => store.getters.getCurrentUrlWeb);

const fetchData = async () => {
  try {
    const response = await axios.get('/api/videos', {
      headers: {
        'X-API-Key': process.env.VUE_APP_API_KEY
      }
    });

    videosList.value = response.data.map(video => ({
      id: video.id,
      date: video.date,
      title: video.title,
      artwork: video.artwork,
      type: video.type,
      enlace: video.enlace,
      iframe: video.iframe,
      extraInfo: video.extraInfo
    }));

    typesOfVideosList();
  } catch (error) {
    console.error('Error fetching video data:', error);
  }
};

const typesOfVideosList = () => {
  const grouped = {};

  videosList.value.forEach((video) => {
    if (!grouped[video.type]) {
      grouped[video.type] = [];
    }

    grouped[video.type].push(video);
  });

  typesOfVideos.value = Object.keys(grouped).map((type) => ({
    type,
    videoList: grouped[type].sort((a, b) => new Date(b.date) - new Date(a.date)),
  }));
};

const handleSection = (index, type) => {
  activeSection.value = index;
  smoothScrollToSection(type);
};

const handleScroll = () => {
  typesOfVideos.value.forEach((section, index) => {
    const sectionElement = document.getElementById(section.type);

    if (sectionElement) {
      const rect = sectionElement.getBoundingClientRect();

      if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
        activeSection.value = index; // Usar el índice como valor activo
      }
    }
  });
};

const handleVideo = (id, iframe) => {
  if (iframe !== null) {
    videoClick.value = id;
  } else {
    videoClick.value = -1;
  }

  //hacer logica de cuando va atras pause el video o cierre, logica v if no sirve porque no hace bien la animacion
};

const smoothScrollToSection = (sectionId) => {
  const sectionElement = document.getElementById(sectionId);

  if (sectionElement) {
    sectionElement.scrollIntoView({
      behavior: 'smooth',
    });
  }
};

const getYear = (dateTime) => new Date(dateTime).getFullYear();

const handleHover = (index, type) => {
  hoverCard.value = index;
  hoverSection.value = type;
  playVideo(index, type);
};

const handleLeave = () => {
  pauseVideo(hoverCard.value, hoverSection.value);
  hoverSection.value = -1;
  hoverCard.value = -1;
};

const detectMobile = () => {
  const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return mobileRegex.test(navigator.userAgent);
}

onMounted(() => {
  fetchData();
  isMobile.value = detectMobile();
});

const playVideo = (cardIndex, sectionType) => {
  // Obtener el elemento de video
  const videoElement = document.getElementById(`video-${cardIndex}${sectionType}`);
  if (videoElement && videoElement.play) {
    videoElement.play();
  }
};

const pauseVideo = (cardIndex, sectionType) => {
  // Obtener el elemento de video
  const videoElement = document.getElementById(`video-${cardIndex}${sectionType}`);
  if (videoElement && videoElement.pause) {
    videoElement.pause();
  }
};
</script>

<style scoped>
/* nav */
.video-menu {
  align-items: center;
  display: flex;
  font-size: 0;
  left: 50%;
  position: absolute;
  top: 6rem;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 3;
  opacity: 1;
  transition: opacity .4s ease .4s, visibility .4s ease .4s;
  visibility: visible;
}

@media (max-width: 768px) {
  .video-menu {
    top: 4rem
  }
}

@media (max-width: 768px) {
  .video-line {
    margin: auto;
    max-width: 50rem;
  }

  .videos-section:last-child .video-line {
    padding-bottom: 16rem
  }

  .videos-section:not(:first-child) .video-line {
    padding-top: 16rem
  }
}

.video-menu-divider {
  display: inline-block;
  font-size: 3rem;
  opacity: .5;
  vertical-align: middle
}

@media (max-width: 768px) {
  .video-menu-divider {
    font-size: 2.5rem
  }
}

.video-menu-button {
  font-size: 3rem;
  opacity: .5;
  transition: opacity .4s ease;
  vertical-align: middle
}

.video-menu-button:hover {
  opacity: .25
}

.video-menu-button--active, .video-menu-button--active:hover {
  opacity: 1;
}

@media (max-width: 768px) {
  .video-menu-button {
    font-size: 3rem
  }
}

/* Anula el overlay de v-card */
::v-deep(.v-card__overlay) {
  display: none !important;
}

::v-deep(.v-ripple__container) {
  display: none !important;
}

.video-container {
  opacity: 1;
  pointer-events: auto;
  transition: opacity .3s ease .3s, visibility .3s ease .3s;
  visibility: visible;
  z-index: 3;
}

.video-section:first-child {
  padding-top: 16rem;
}

.video-section:not(:first-child) {
  padding-top: 16rem;
}

.video-section {
  margin: auto;
  max-width: 168rem;
}

.video-section:last-child {
  padding-bottom: 28rem;
}

.video-section--tv .video-line .videos-section-video-button, .video-section--music .video-line .videos-section-video-button {
  display: block;
  position: relative;
  padding-top: 56.25%;
  width: 100%;
}

.video-section--reels .video-line .videos-section-video-link {
  display: block;
  position: relative;
  padding-top: 177.77778%;
}

.video-section--tv .video-col:nth-child(1n+2) {
  margin-top: 6rem;
}

.video-section--reels .video-col:nth-child(1n+5) {
  margin-top: 6rem;
}

.video-section--music .video-col:nth-child(1n+3) {
  margin-top: 6rem;
}

.video-col {
  display: inline-block;
}

.video-section--tv .video-line {
  display: block;
  margin: auto;
  width: 108rem;
}

.videos-wrapper {
  overflow-y: scroll;
  height: 100%;
}

.video-card {
  background: none;
  padding: 0 4rem;
}

.video-title {
  text-align: center;
  margin-top: 2.4rem;
  font-weight: bold;
  font-size: 2.4rem;
  transition: opacity .4s ease;
}

.video-click .video-card.video-card-active, .video-click .video-title.video-card-active {
  opacity: 1
}

.video-click .video-col:hover .video-title.video-card-active {
  opacity: 1;
}

.video-click .video-card, .video-click .video-title {
  opacity: .35
}

.video-click .video-menu {
  opacity: 0;
  transition: opacity .4s ease, visibility .4s ease;
  visibility: hidden
}

.video-col:hover .video-title {
  opacity: .5;
}

.v-card:hover > .v-card-overlay {
  opacity: initial;
}

.video {
  background: #000;
  opacity: 0;
  transition: opacity .4s ease .4s, visibility .4s ease .4s;
  visibility: hidden;
  z-index: 3
}

.video--active {
  opacity: 1;
  visibility: visible
}

.video--active, .video-modal {
  transition: opacity .4s ease, visibility .4s ease
}

.video-modal {
  align-items: center;
  display: flex;
  justify-content: center;
  opacity: 0;
  padding: 12rem 0;
  visibility: hidden
}

.video--active .video-modal {
  opacity: 1;
  transition: opacity .4s ease .4s, visibility .4s ease .4s;
  visibility: visible
}

@media (max-width: 768px) {
  .video-modal {
    padding: 0
  }

  .video-section--music .video-line, .video-section--reels .video-line, .video-section--tv .video-line {
    margin: auto;
    width: 100%;
  }

  .video-card {
    padding: 0 1rem;
  }

  .video-title {
    font-size: 2rem;
  }

  .video-section--reels .video-col:not(:first-child),
  .video-section--tv .video-col:not(:first-child),
  .video-section--music .video-col:not(:first-child) {
    margin-top: 6rem;
  }

  .video-section:first-child {
    padding-top: 10rem;
  }
}

.video-button {
  left: 6rem;
  position: absolute;
  transition: opacity .4s ease;
  top: 6rem;
  font-size: 3rem;
}

.video-button:hover {
  opacity: .5
}

@media (max-width: 768px) {
  .video-button {
    left: 3rem;
    top: 4rem
  }
}

.video-wrapper {
  width: 108rem
}

@media (max-width: 768px) {
  .video-wrapper {
    width: 42rem
  }
}

.video-box {
  height: 60.75rem;
  max-height: 100%;
  position: relative;
  width: 108rem
}

@media (max-width: 768px) {
  .video-box {
    height: 23.5rem;
    width: 42rem
  }
}

.video-box-iframe {
  -o-object-fit: inherit;
  object-fit: inherit;
  opacity: 0;
  transition: opacity .4s ease .4s
}

.video-box-iframe--active {
  opacity: 1
}

.video-content {
  display: flex;
  margin-top: 2.5rem
}

@media (max-width: 768px) {
  .video-content {
    flex-direction: column
  }
}

.video-content-information {
  width: 50%
}

@media (max-width: 768px) {
  .video-content-information {
    width: 100%
  }
}

.video-content-credits {
  text-align: right;
  width: 50%
}

@media (max-width: 768px) {
  .video-content-credits {
    margin-top: 1rem;
    text-align: left;
    width: 100%
  }
}
</style>