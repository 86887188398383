// src/main.js
import {createApp} from 'vue';
import CordobesAndresitoApp from './CordobesAndresito.vue';
import axios from 'axios';
import router from './router';
import PrimeVue from 'primevue/config';
import Ripple from 'primevue/ripple';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import store from './store';
import {createVuetify} from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
    components,
    directives
})

// Configure Axios to point to the backend server
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

const app = createApp(CordobesAndresitoApp);

app.config.globalProperties.$axios = axios;

app.use(router); // Use the router plugin
app.use(vuetify)
app.use(store)

app.directive('ripple', Ripple);
app.use(PrimeVue, {ripple: true});
app.use(ToastService);
app.use(ConfirmationService);

app.mount('#cordobesandresito');
