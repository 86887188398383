<template>
  <div :class="{ 'news': true, 'news-container': true }">
    <nav class="news-menu">
      <button class="news-menu-button news-menu-button--active" value="news">
        VIP
      </button>
    </nav>

    <div class="news-wrapper">
      <div class="news-content">
        <div class="news-description">
          <p>Regístrate en nuestra newsletter para recibir todos los avisos de eventos, nuevos remixes y novedades</p>
        </div>

        <div class="news-form">
          <div class="news-inputs">
            <InputGroup>
              <InputGroupAddon>
                <i class="pi pi-user"></i>
              </InputGroupAddon>
              <InputText v-model="nombre" placeholder="Nombre / Alias"/>
            </InputGroup>

            <InputGroup>
              <InputGroupAddon>
                <i class="pi pi-envelope"></i>
              </InputGroupAddon>
              <InputText v-model="email" placeholder="Email" autocapitalize="off"/>
            </InputGroup>
          </div>

          <div class="news-checks">
            <div class="news-check">
              <label for="switch1">Música</label>
              <InputSwitch inputId="switch1" v-model="musicChecked"/>
            </div>

            <div class="news-check">
              <label for="switch2">Eventos</label>
              <InputSwitch inputId="switch2" v-model="eventsChecked"/>
            </div>

            <div class="news-check">
              <label for="switch3">Novedades</label>
              <InputSwitch inputId="switch3" v-model="newsChecked"/>
            </div>
          </div>

          <div class="news-button">
            <Toast/>
            <SplitButton label="Alta" :model="items" @click="submitForm" rounded></SplitButton>
          </div>

          <ConfirmDialog group="headless">
            <template #container="{ message, acceptCallback, rejectCallback }">
              <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
                <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
                <p class="mb-0">{{ message.message }}</p>
                <div class="flex align-items-center gap-2 mt-4">
                  <Button label="Save" @click="acceptCallback"></Button>
                  <Button label="Cancel" outlined @click="rejectCallback"></Button>
                </div>
              </div>
            </template>
          </ConfirmDialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from 'vue';
import axios from 'axios';

import {useToast} from "primevue/usetoast";
import {useConfirm} from "primevue/useconfirm";
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import InputText from 'primevue/inputtext';
import InputSwitch from 'primevue/inputswitch';
import Toast from 'primevue/toast';
import SplitButton from 'primevue/splitbutton';
import ConfirmDialog from 'primevue/confirmdialog';
import Button from 'primevue/button';

import 'primevue/resources/primevue.min.css';
import 'primevue/resources/themes/aura-dark-purple/theme.css';
import 'primeicons/primeicons.css';

const musicChecked = ref(false);
const eventsChecked = ref(false);
const newsChecked = ref(false);
const toast = useToast();
const confirm = useConfirm();

// Variables reactivas para almacenar el nombre y el correo electrónico
const nombre = ref('');
const email = ref('');

//optimizar toast pasando por parametro los datos
const showErrorToast = (error) => {
  toast.add({severity: 'error', summary: 'Error', detail: error.message || error, life: 3000});
};

const validateInput = (type = null) => {
  // Verificar si el nombre y el correo electrónico están presentes
  if ((!nombre.value.trim() && (type !== 'update' && type !== 'delete')) || !email.value.trim()) {
    showErrorToast('Por favor ingrese su nombre y correo electrónico.');
    return false;
  }

  // Validar el formato del correo electrónico usando una expresión regular
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email.value.trim())) {
    showErrorToast('Por favor ingrese un correo electrónico válido.');
    return false;
  }

  // Verificar que el alias tenga más de 3 letras
  if ((nombre.value.trim().length < 4 || nombre.value.trim().length > 16) && (type !== 'update' && type !== 'delete')) {
    showErrorToast('El nombre debe estar entre 4 y 16 caracteres.');
    return false;
  }

  // Verificar que al menos un switch esté marcado
  if ((!musicChecked.value && !eventsChecked.value && !newsChecked.value) && (type !== 'delete')) {
    showErrorToast('Debe seleccionar al menos una opción.');
    return false;
  }

  return true;
};

const updateData = async () => {
  try {
    const response = await axios.get(`/api/verifyEmail?email=${email.value}`);
    if (response.data.exists) {
      try {
        // Lógica para actualizar los datos
        const newData = {
          nombre: nombre.value,
          email: email.value,
          musicChecked: musicChecked.value,
          eventsChecked: eventsChecked.value,
          newsChecked: newsChecked.value
        };
        await axios.post('/api/updateData', newData, {
          headers: {
            'X-API-Key': process.env.VUE_APP_API_KEY
          }
        });

        // Una vez actualizados los datos, mostrar un mensaje de éxito
        toast.add({
          severity: 'success',
          summary: 'Datos actualizados',
          detail: 'Los datos se han actualizado correctamente',
          life: 3000
        });

        // Restablecer los campos
        nombre.value = '';
        email.value = '';
        musicChecked.value = false;
        eventsChecked.value = false;
        newsChecked.value = false;
      } catch (error) {
        console.error('Error al actualizar los datos:', error);
        showErrorToast('Error al actualizar los datos')
      }
    } else {
      // Si el correo electrónico no existe, mostrar un mensaje de error
      showErrorToast('El correo electrónico no existe')
    }
  } catch (error) {
    showErrorToast('Error al verificar el correo electrónico')
  }
};

const deleteData = async () => {
  try {
    const response = await axios.get(`/api/verifyEmail?email=${email.value}`);
    if (response.data.exists) {
      try {
        // Lógica para eliminar los datos
        const dataToDelete = {
          email: email.value,
        };
        await axios.post('/api/deleteData', dataToDelete, {
          headers: {
            'X-API-Key': process.env.VUE_APP_API_KEY
          }
        });

        // Una vez eliminados los datos, mostrar un mensaje de éxito
        toast.add({
          severity: 'success',
          summary: 'Datos eliminados',
          detail: 'Los datos se han eliminado correctamente',
          life: 3000
        });

        // Restablecer los campos
        nombre.value = '';
        email.value = '';
        musicChecked.value = false;
        eventsChecked.value = false;
        newsChecked.value = false;
      } catch (error) {
        console.error('Error al eliminar los datos:', error);
        showErrorToast('Error al eliminar los datos')
      }
    } else {
      // Si el correo electrónico no existe, mostrar un mensaje de error
      showErrorToast('El correo electrónico no existe')
    }
  } catch (error) {
    showErrorToast('Error al verificar el correo electrónico')
  }
};

const submitForm = () => {
  if (!validateInput()) {
    return;
  }

  // Si se proporciona el nombre y el correo electrónico, continuar con el reCAPTCHA
  /* global grecaptcha */
  grecaptcha.ready(() => {
    grecaptcha.execute('6LdDLmcpAAAAAG5n9bmpQ-yrtMj9GKcv4Ql21Zeu', { action: 'submit' }).then(token => {
      axios.post('/api/verificar-recaptcha', { token }, {
        headers: {
          'X-API-Key': process.env.VUE_APP_API_KEY,
        },
      })
          .then(() => {
            // Enviar datos al backend
            axios.post('/api/submitForm', {
              email: email.value,
              nombre: nombre.value,
              musicChecked: musicChecked.value,
              eventsChecked: eventsChecked.value,
              newsChecked: newsChecked.value,
            }, {
              headers: {
                'X-API-Key': process.env.VUE_APP_API_KEY,
              },
            })

            toast.add({
              severity: 'success',
              summary: 'Guardado correctamente',
              detail: 'Tu correo ya se ha grabado en la base de datos',
              life: 3000
            });

            // Restablecer los campos
            nombre.value = '';
            email.value = '';
            musicChecked.value = false;
            eventsChecked.value = false;
            newsChecked.value = false;
          })
          .catch(error => {
            console.error(error);
            showErrorToast('reCAPTCHA inválido.')
          });
    });
  });
};

const items = [
  {
    label: 'Actualizar',
    icon: 'pi pi-refresh',
    command: () => {
      requireConfirmation('update', '¿Estás seguro de modificar los datos del correo ' + email.value + '?', 'Se modificará tanto el alias si lo has rellenado, como los tipos de aviso', 'warn', 'No actualizado', 'Has rechazado la operación');
    }
  },
  {
    label: 'Borrar',
    icon: 'pi pi-times',
    command: () => {
      requireConfirmation('delete', '¿Estás seguro de borrar de la newsletter ' + email.value + '?', 'Ya no recibirás ningun aviso de ningun tipo', 'warn', 'No borrado', 'Has rechazado la operación');
    }
  }
];

const requireConfirmation = (type, headerMessage, message, typeError, summary, detail) => {
  if (!validateInput(type)) {
    return;
  }

  confirm.require({
    group: 'headless',
    header: headerMessage,
    message: message,
    accept: () => {
      if (type === 'update') {
        updateData()
      } else if (type === 'delete') {
        deleteData()
      }
    },
    reject: () => {
      toast.add({severity: typeError, summary: summary, detail: detail, life: 3000});
    }
  });
};
</script>

<style scoped>
/* nav */
.news-menu {
  align-items: center;
  display: flex;
  font-size: 0;
  left: 50%;
  position: absolute;
  top: 6rem;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 3;
  opacity: 1;
  transition: opacity .4s ease .4s, visibility .4s ease .4s;
  visibility: visible;
}

@media (max-width: 768px) {
  .news-menu {
    top: 4rem
  }
}

.news-menu-divider {
  display: inline-block;
  font-size: 3rem;
  opacity: .5;
  vertical-align: middle
}

@media (max-width: 768px) {
  .news-menu-divider {
    font-size: 2.5rem
  }
}

.news-menu-button {
  font-size: 3rem;
  opacity: .5;
  transition: opacity .4s ease;
  vertical-align: middle
}

.news-menu-button:hover {
  opacity: .25
}

.news-menu-button--active, .news-menu-button--active:hover {
  opacity: 1;
}

@media (max-width: 768px) {
  .news-menu-button {
    font-size: 3rem
  }
}

.news-container {
  opacity: 1;
  pointer-events: auto;
  transition: opacity .3s ease .3s, visibility .3s ease .3s;
  visibility: visible;
  z-index: 3;
}

.news-wrapper {
  overflow-y: scroll;
  height: 100%;
}

.news-content {
  margin: auto;
  padding-bottom: 28rem;
  padding-top: 16rem;
  width: 120rem
}

@media (max-width: 768px) {
  .news-content {
    font-size: 1.75rem;
    padding-bottom: 16rem;
    width: 42rem
  }
}

.news-description, .news-form {
  margin: auto;
  max-width: 90rem;
}

.news-description {
  margin: 0 auto 6.5rem;
}

.news-description p {
  line-height: 1.2;
}

.news-inputs {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}

.news-description p:not(:first-child) {
  margin-top: 4rem;
}

@media (max-width: 768px) {
  .news-description, .news-form {
    font-size: 2.5rem;
    padding: 0;
  }

  .news-inputs {
    display: block;
  }
}

.news-button {
  width: 96%;
  text-align: center;
}

.news-checks {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 3rem;
}

.news-check {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.news-checks label {
  font-size: 2.5rem;
  padding-right: 1rem;
}

.p-inputtext:enabled:focus {
  outline: 1px solid var(--p-focus-ring-color);
}

.p-inputgroup {
  height: 3rem;
  width: 45%
}

.p-inputtext {
  font-size: 1.5rem;
  padding: 0.5rem 0.75rem;
}

.input-col {
  margin-bottom: 16px;
}

.input-field {
  width: 100%;
}

.submit-button {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #333;
}

@media (max-width: 768px) {
  .p-inputgroup {
    width: 100%;
    height: 6rem;
    margin: 2rem 0;
  }

  .news-checks {
    flex-direction: column;
  }

  .p-inputgroup-addon {
    min-width: 4.5rem;
  }

  .pi {
    font-size: 1.5rem;
  }

  .p-inputtext {
    font-size: 2rem;
  }
}
</style>