<template>
  <div :class="{ 'music': true, 'music-container': true, 'music-click': isMusicClick }">
    <nav class="music-menu">
      <div v-for="(section, index) in typesOfMusic" :key="section.type">
        <button @click="handleSection(index, section.type)"
                :class="{ 'music-menu-button': true, 'music-menu-button--active': activeSection === index }">
          {{ section.type }}
        </button>
        <div v-if="index < typesOfMusic.length - 1" class="music-menu-divider">&nbsp;&nbsp;/&nbsp;&nbsp;</div>
      </div>
    </nav>

    <div class="music-wrapper" @scroll="handleScroll">
      <div v-for="(section, sectionIndex) in typesOfMusic" :key="section.type" :id="section.type" class="music-section">
        <v-row class="music-line">
          <v-col class="music-col" v-for="(music, cardIndex) in section.musicList" :key="cardIndex" cols="12" sm="4">
            <v-card class="mx-auto my-4" @click="handleClick(sectionIndex, cardIndex, music)" :data-id="music.id"
                    :class="{ 'music-card': true, 'music-card-active': activeCardIndex[sectionIndex] === cardIndex }">
              <v-img class="music-artwork" :src="music.artwork" height="48rem" width="48rem"
                     alt="Artwork de la canción"></v-img>
            </v-card>
            <v-card-title class="music-title"
                          :class="{ 'music-card-active': activeCardIndex[sectionIndex] === cardIndex }">{{
                music.title
              }}
            </v-card-title>
          </v-col>
        </v-row>
      </div>
    </div>

    <div class="music-links">
      <div v-for="(music) in musicList" :key="music.type" :id="music.id">
        <p :class="{ 'music-links-item': true, 'music-links-item--active': isMusicClick && musicId === music.id }"
           :data-id="music.id">
          <a v-for="(link, typeIndex) in getMusicLinks(music)"
             :key="typeIndex" :href="link.url" target="_blank" rel="noopener" class="music-link-name"
             :class="{ 'has-divider': typeIndex < getMusicLinks(music).length - 1}">{{ link.label }}<span
              v-if="typeIndex < getMusicLinks(music).length - 1" class="music-links-divider">/</span>
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted, onUnmounted} from 'vue';
import axios from 'axios';
import {useStore} from "vuex";

const store = useStore();
import {computed} from "vue";

let isMusicClick = ref(false);
let activeSection = ref(0);
let musicId = ref(0);
let activeCardIndex = ref({});

const typesOfMusic = ref([]);
const musicList = ref([]);

const currentUrl = computed(() => store.getters.getCurrentUrlWeb);

const fetchData = async () => {
  try {
    const response = await axios.get('/api/music', {
      headers: {
        'X-API-Key': process.env.VUE_APP_API_KEY
      }
    })
    musicList.value = response.data.map(music => ({
      id: music.id,
      title: music.title,
      artwork: music.artwork.includes("#%ruta%#") ? music.artwork.replace("#%ruta%#", currentUrl.value) : music.artwork,
      type: music.type,
      Youtube: music.Youtube ? 'https://www.youtube.com/watch?v=' + music.Youtube : '',
      Spotify: music.Spotify,
      Soundcloud: music.Soundcloud,
      Descarga: music.Descarga,
      Date: music.date_script
    }));

    typesOfMusicList();
  } catch (error) {
    console.error('Error fetching music data:', error);
  }
};

const handleClick = (sectionIndex, cardIndex, music) => {
  if (!isMusicClick.value) {
    isMusicClick.value = true;
    activeCardIndex.value[sectionIndex] = cardIndex;
    musicId.value = music.id;
  } else {
    isMusicClick.value = false;
    activeCardIndex.value[sectionIndex] = cardIndex;
    musicId.value = 0;
  }
};

const handleClickOutside = (event) => {
  const clickedCard = event.target.closest('.music-card');

  if (isMusicClick.value && clickedCard === null) {
    isMusicClick.value = false;
    activeCardIndex.value = {};
  }
}

const smoothScrollToSection = (sectionId) => {
  const sectionElement = document.getElementById(sectionId);

  if (sectionElement) {
    sectionElement.scrollIntoView({
      behavior: 'smooth',
    });
  }
};

const handleSection = (index, type) => {
  activeSection.value = index;
  smoothScrollToSection(type);
};

const handleScroll = () => {
  typesOfMusic.value.forEach((section, index) => {
    const sectionElement = document.getElementById(section.type);

    if (sectionElement) {
      const rect = sectionElement.getBoundingClientRect();

      if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
        activeSection.value = index; // Usar el índice como valor activo
      }
    }
  });
};

const typesOfMusicList = () => {
  const grouped = {};

  musicList.value.forEach((music) => {
    if (!grouped[music.type]) {
      grouped[music.type] = [];
    }

    grouped[music.type].push(music);
  });

  // Ordenar las secciones por fecha
  typesOfMusic.value = Object.keys(grouped).map((type) => ({
    type,
    musicList: grouped[type].sort((a, b) => new Date(b.Date) - new Date(a.Date)), // Ordenar por fecha descendente
  }));
}

const getMusicLinks = (music) => {
  const links = [];

  if (music.Youtube) {
    links.push({label: 'Youtube', url: music.Youtube});
  }

  if (music.Spotify) {
    links.push({label: 'Spotify', url: music.Spotify});
  }

  if (music.Soundcloud) {
    links.push({label: 'Soundcloud', url: music.Soundcloud});
  }

  if (music.Descarga) {
    links.push({label: 'Descargar', url: music.Descarga});
  }

  return links;
};

onMounted(() => {
  fetchData();
  document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>

<style scoped>
/* nav */
.music-menu {
  align-items: center;
  display: flex;
  font-size: 0;
  left: 50%;
  position: absolute;
  top: 6rem;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 3;
  opacity: 1;
  transition: opacity .4s ease .4s, visibility .4s ease .4s;
  visibility: visible;
}

@media (max-width: 768px) {
  .music-menu {
    top: 4rem
  }
}

.music-menu-divider {
  display: inline-block;
  font-size: 3rem;
  opacity: .5;
  vertical-align: middle
}

@media (max-width: 768px) {
  .music-menu-divider {
    font-size: 2.5rem
  }
}

.music-menu-button {
  font-size: 3rem;
  opacity: .5;
  transition: opacity .4s ease;
  vertical-align: middle
}

.music-menu-button:hover {
  opacity: .25
}

.music-menu-button--active, .music-menu-button--active:hover {
  opacity: 1;
}

@media (max-width: 768px) {
  .music-menu-button {
    font-size: 3rem
  }
}

/* Anula el overlay de v-card */
::v-deep(.v-card__overlay) {
  display: none !important;
}

::v-deep(.v-ripple__container) {
  display: none !important;
}

.music-container {
  opacity: 1;
  pointer-events: auto;
  transition: opacity .3s ease .3s, visibility .3s ease .3s;
  visibility: visible;
  z-index: 3;
}

.music-section:first-child {
  padding-top: 16rem;
}

.music-section:not(:first-child) {
  padding-top: 16rem;
}

.music-section {
  margin: auto;
  max-width: 168rem;
}

.music-section:last-child {
  padding-bottom: 28rem;
}

.music-line {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -6rem;
}

.music-wrapper {
  overflow-y: scroll;
  height: 100%;
}

.music-card {
  background: none;
  display: block;
  padding: 0 6rem;
}

.music-artwork {
  position: initial;
}

.music-title {
  text-align: center;
  margin-top: 2.4rem;
  font-weight: bold;
  font-size: 2.4rem;
  transition: opacity .4s ease;
}

.music-click .music-card.music-card-active, .music-click .music-title.music-card-active {
  opacity: 1
}

.music-click .music-col:hover .music-title.music-card-active {
  opacity: 1;
}

.music-click .music-card, .music-click .music-title {
  opacity: .35
}

.music-click .music-menu {
  opacity: 0;
  transition: opacity .4s ease, visibility .4s ease;
  visibility: hidden
}

.music-col:nth-child(1n+4) {
  margin-top: 10rem;
}

.music-col:hover .music-title {
  opacity: .5;
}

.v-card:hover > .v-card-overlay {
  opacity: initial;
}

.music-links {
  left: 0;
  position: absolute;
  top: 6rem;
  width: 100%;
  z-index: 2;
}

.music-links-item a {
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top
}

.music-links-divider {
  vertical-align: middle;
  padding: 0 5px 0 5px;
}

@media (max-width: 768px) {
  .music-links {
    top: 4rem
  }

  .music-line {
    margin: 0 auto;
  }

  .music-section:first-child {
    padding-top: 12rem;
  }

  .music-title {
    padding: 0.5rem 0.5rem 3rem;
  }
}

.music-links-item {
  align-items: center;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 0;
  transition: opacity .4s ease, visibility .4s ease;
  visibility: hidden;
  width: 100%
}

.music-links-item--active {
  opacity: 1;
  transition: opacity .4s ease .4s, visibility .4s ease .4s;
  visibility: visible
}

@media (max-width: 768px) {
  .music-links-item {
    padding: 0 6rem;
    display: flow;
  }

  .music-links-item .music-link-name {
    font-size: 3rem;
  }
}

.music-links-item .music-link-name {
  display: inline-block;
}

.music-links-item .music-link-name:before {
  background: currentColor;
  bottom: 0;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  transition: transform .7s cubic-bezier(.19, 1, .22, 1);
  width: 100%;
}

.music-links-item .music-link-name.has-divider:before {
  width: calc(100% - 16px);
}

.music-links-item .music-link-name:focus:before,
.music-links-item .music-link-name:hover:before {
  transform: scaleX(1);
  transform-origin: left center;
}

.music-links-item .music-link-name:before {
  transform: scaleX(0);
  transform-origin: right center;
}


</style>
