<template>
  <div :class="{ 'galeria': true, 'galeria-container': true }">

    <button class="galeria-expand" :class="{'galeria-expand--active': isExpanded}">
      <span class="galeria-expand-text" @click="handleOpenAll()">↓ Abrir todas</span>
      <span class="galeria-expand-text" @click="handleCloseAll()">↑ Cerrar todas</span>
    </button>

    <div class="galeria-wrapper">
      <div class="galeria-list">
        <div v-for="(galeria) in galeriaList" :key="galeria.id" class="galeria-list-item"
             @click="handleGaleria(galeria.id, $event)"
             :class="{'galeria-list-item--active': openGaleria.indexOf(galeria.id) !== -1}">
          <header class="galeria-list-item-header">
            <p class="galeria-list-item-year">
              {{ getYear(galeria.date) }}
            </p>
            <p class="galeria-list-item-title">
              {{ galeria.title }}
            </p>
            <div class="galeria-list-item-description">
              <p>
                <a class="galeria-list-item-link" :href="galeria.url" target="_blank"
                   rel="noopener">{{ galeria.location }}</a>
              </p>
            </div>
          </header>

          <div class="galeria-list-item-content"
               :style="{ maxHeight: openGaleria.indexOf(galeria.id) !== -1 ? '100rem' : '0px' }">
            <div class="galeria-list-item-content-wrapper">
              <div class="gallery-component">
                <div class="gallery-component-wrapper">
                  <swiper
                      :centeredSlides="true"
                      :navigation="true"
                      :modules="modules"
                      :pagination="{
                      clickable: true,
                       }"
                      class="swiper-container-galeria">

                    <swiper-slide v-for="(image, index) in galeria.images" :key="index" class="swiper-slide">
                      <img :src="currentUrl + galeria.directorio + image"
                           :style="{ height: galeria.maxHeight, objectFit: galeria.fit }"
                           alt="Slide {{ index + 1 }}" />
                    </swiper-slide>
                  </swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import axios from 'axios';
// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {Autoplay, Navigation, Pagination} from "swiper/modules";
import {useStore} from "vuex";

const galeriaList = ref([]);
const openGaleria = ref([]);
const isExpanded = ref(false);
const modules = ref([Autoplay, Pagination, Navigation])
const store = useStore();
import {computed} from "vue";

const currentUrl = computed(() => store.getters.getCurrentUrlWeb);

const fetchData = async () => {
  try {
    const response = await axios.get('/api/galeria', {
      headers: {
        'X-API-Key': process.env.VUE_APP_API_KEY
      }
    });

    // Ordenar por fecha descendente (de más reciente a más antigua)
    const sortedData = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));

    galeriaList.value = sortedData.map(galeria => ({
      id: galeria.id,
      date: galeria.date,
      title: galeria.title,
      location: galeria.location,
      url: galeria.url,
      directorio: galeria.directorio !== null ? galeria.directorio : '',
      orientacion: galeria.orientacion,
      images: galeria.imagenes.split(',').map(image => image.trim()),
      open: galeria.open
    }));

    // Agregar las galerías abiertas a la lista openGaleria
    galeriaList.value.forEach(galeria => {
      if (galeria.open) {
        openGaleria.value.push(galeria.id);
      }
    });

  } catch (error) {
    console.error('Error fetching galeria data:', error);
  }
};

const handleGaleria = (id, event) => {
  const clickedContainer = event.target.closest('.galeria-list-item');
  const clickedSwiper = event.target.closest('.gallery-component-wrapper');

  // Verificar si se hizo clic dentro de la galería actualmente abierta
  const isSameGaleria = openGaleria.value.indexOf(id) !== -1;

  // Verificar si se hizo clic en el área de contenido de la galería
  const isContentClick = event.target.closest('.galeria-list-item-content') !== null;
  if (clickedContainer !== null) {
    if (isSameGaleria && clickedSwiper === null && !isContentClick) {
      openGaleria.value.splice(openGaleria.value.indexOf(id), 1);
    } else if (clickedSwiper === null) {
      openGaleria.value.push(id);
    }
  }
};

const handleOpenAll = () => {
  isExpanded.value = true;

  galeriaList.value.forEach(galeria => {
    openGaleria.value.push(galeria.id);
  })
}

const handleCloseAll = () => {
  isExpanded.value = false;
  openGaleria.value = [];
}

const getYear = (dateTime) => new Date(dateTime).getFullYear();

const MAX_HEIGHT = '30vw';
const MAX_HEIGHT_HORIZONTAL = '35vw';
const MAX_HEIGHT_MOBILE = '120vw';
const MAX_HEIGHT_HORIZONTAL_MOBILE = '100%';
const FIT = 'contain';
const FIT_HORIZONTAL = 'cover';

// Detectar dispositivo móvil
const isMobile = window.matchMedia('(max-width: 768px)').matches;

const setUniformHeight = () => {
  galeriaList.value.forEach(album => {
    let maxHeight = 0;
    let fit = 'cover';
    let orientacion = album.orientacion;

    album.images.forEach(image => {
      const img = new Image();
      img.src = currentUrl.value + album.directorio + image;
      img.onload = () => {
        // Ajusta la altura al límite en función del dispositivo
        if (orientacion === 'vertical') {
          maxHeight = isMobile ? MAX_HEIGHT_MOBILE : MAX_HEIGHT;
          fit = FIT;
        } else if (orientacion === 'horizontal') {
          maxHeight = isMobile ? MAX_HEIGHT_HORIZONTAL_MOBILE : MAX_HEIGHT_HORIZONTAL;
          fit = FIT_HORIZONTAL;
        }

        album.maxHeight = maxHeight;
        album.fit = fit;
      };
    });
  });
};

onMounted(() => {
  fetchData().then(() => {
    setUniformHeight();
  });
});
</script>

<style scoped>
.galeria-container {
  opacity: 1;
  pointer-events: auto;
  transition: opacity .3s ease .3s, visibility .3s ease .3s;
  visibility: visible;
  z-index: 3;
}

.galeria-wrapper {
  overflow-y: scroll;
  height: 100%;
}

.galeria-expand, .tour-expand {
  left: 6rem;
  position: absolute;
  top: 6rem;
  transition: opacity .4s ease;
  white-space: nowrap;
  z-index: 3
}

.galeria-expand:hover, .tour-expand:hover {
  opacity: .5
}

@media (max-width: 768px) {
  .galeria-expand, .tour-expand {
    left: 3rem;
    top: 4rem
  }
}

.galeria-expand--active span:first-child {
  opacity: 0;
  transition: opacity .4s ease
}

.galeria-expand--active span:last-child {
  opacity: 1;
  visibility: visible;
  transition: opacity .4s ease .2s, visibility 0s .4s; /* Agrega transición para visibility */
}

.galeria-expand-text {
  left: 0;
  position: absolute;
  top: 0
}

.galeria-expand-text:first-child {
  opacity: 1;
  transition: opacity .4s ease .2s
}

.galeria-expand-text:last-child {
  opacity: 0;
  visibility: hidden;
  transition: opacity .4s ease .2s, visibility 0s .4s; /* Agrega transición para visibility */
}

.galeria-list, .press-index {
  margin: auto;
  padding-bottom: 28rem;
  padding-top: 16rem;
  width: 120rem
}

@media (max-width: 768px) {
  .galeria-list, .press-index {
    font-size: 2rem;
    padding-bottom: 16rem;
    width: 42rem
  }
}

.galeria-list-item:not(:first-child), .press-index-item:not(:first-child) {
  margin-top: 5rem
}

.galeria-list-item-header, .press-index-item-header {
  align-items: flex-start;
  display: flex;
  line-height: 1.2;
  min-height: 7.6rem;
  transition: opacity .4s ease
}

.galeria-list-item-header:hover, .press-index-item-header:hover {
  opacity: .5
}

.galeria-list-item--active .galeria-list-item-header:hover, .galeria-list-item--active .press-index-item-header:hover, .press-index-item--active .galeria-list-item-header, .press-index-item--active .press-index-item-header {
  opacity: 1
}

@media (max-width: 768px) {
  .galeria-list-item-header, .press-index-item-header {
    min-height: 5.5rem;
  }
}

.galeria-list-item-year, .press-index-item-year {
  width: 12rem
}

@media (max-width: 768px) {
  .galeria-list-item-year, .press-index-item-year {
    width: 6rem
  }
}

.galeria-list-item-description, .press-index-item-description {
  text-align: right;
  width: 55rem;
  display: flex;
  justify-content: flex-end;
}

.galeria-list-item-description a[href]:last-child:after {
  background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="26.53" width="26.53"><path fill="%23fff" d="M26.11 11.1l-1.79-.11-.1-7.11L1.58 26.53 0 24.96 22.65 2.31l-7.11-.1-.1-1.79L26.53 0l-.42 11.1z"/></svg>') no-repeat 50%/cover;
  content: "";
  display: inline-block;
  height: 2.4rem;
  margin-left: .75rem;
  margin-top: -.6rem;
  vertical-align: middle;
  width: 2.4rem
}

@media (max-width: 768px) {
  .galeria-list-item-description, .press-index-item-description {
    width: 13.2rem
  }

  .galeria-list-item-description a[href]:last-child:after, .press-index-item-description a[href]:last-child:after {
    height: 1.4rem;
    margin-left: .5rem;
    margin-top: -.2rem;
    width: 1.4rem
  }
}

.galeria-list-item-title, .press-index-item-title {
  width: calc(100% - 55rem)
}

@media (max-width: 768px) {
  .galeria-list-item-title, .press-index-item-title {
    width: calc(100% - 18rem)
  }
}

.galeria-list-item-content, .press-index-item-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height .4s ease .4s
}

.galeria-list-item--active .galeria-list-item-content, .galeria-list-item--active .press-index-item-content, .press-index-item--active .galeria-list-item-content, .press-index-item--active .press-index-item-content {
  transition: max-height .4s ease
}

.galeria-list-item-content-wrapper, .press-index-item-content-wrapper {
  opacity: 0;
  padding-bottom: 5rem;
  transition: opacity .4s ease
}

.galeria-list-item--active .galeria-list-item-content-wrapper, .galeria-list-item--active .press-index-item-content-wrapper, .press-index-item--active .galeria-list-item-content-wrapper, .press-index-item--active .press-index-item-content-wrapper {
  opacity: 1;
  transition: opacity .4s ease .4s
}

@media (max-width: 768px) {
  .galeria-list-item-content-wrapper, .press-index-item-content-wrapper {
    padding-bottom: 0
  }
}

.swiper-slide {
  max-height: 100rem;
}

.galeria-list-item-description .galeria-list-item-link {
  display: inline-block;
}

.galeria-list-item-description .galeria-list-item-link[href]:before {
  background: currentColor;
  bottom: 0;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  transition: transform .7s cubic-bezier(.19, 1, .22, 1);
  width: 100%;
}

.galeria-list-item-description .galeria-list-item-link[href]:focus:before,
.galeria-list-item-description .galeria-list-item-link[href]:hover:before {
  transform: scaleX(1);
  transform-origin: left center;
}

.galeria-list-item-description .galeria-list-item-link[href]:before {
  transform: scaleX(0);
  transform-origin: right center;
}

/* Estilos para centrar y ajustar el tamaño del contenedor */
.swiper-container-galeria {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Evita que se vean imágenes anteriores */
}

/* Estilos para las imágenes dentro de los slides */
.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  margin: auto;
}

/* Oculta las imágenes no activas */
.swiper-slide {
  visibility: hidden;
  transition: opacity 1s ease, visibility 1s ease;
}

/* Muestra solo la imagen activa */
.swiper-slide-active {
  position: relative;
  opacity: 1;
  visibility: visible;
}

/* Controles de navegación y paginación */
.swiper-button-prev, .swiper-button-next {
  color: #fff !important; /* Colores personalizados para la navegación */
}

.swiper-container-galeria .swiper-pagination-bullet,
.swiper-container-galeria .swiper-pagination-bullet-active {
  background: #fff;
}

</style>