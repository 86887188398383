<template>
  <div :class="{ 'info': true, 'info-container': true }">
    <nav class="info-menu">
      <button @click="handleSection('bio')" class="info-menu-button" :class="{'info-menu-button--active': activeSection === 'bio'}" value="bio">
        Bio
      </button>
      <div class="info-menu-divider">&nbsp;&nbsp;/&nbsp;&nbsp;</div>
      <button @click="handleSection('contacto')" class="info-menu-button" :class="{'info-menu-button--active': activeSection === 'contacto'}" value="contacto">
        Contacto
      </button>
    </nav>

    <div class="info-wrapper" @scroll="handleScroll">
      <div class="info-content">
        <div class="info-box" id="bio">
          <div class="info-media">
            <div class="info-media-box">
              <img class="info-media-image info-media-image--active loaded"
                   :data-src="currentUrl + 'bio.jpg'"
                   :src="currentUrl + 'bio.jpg'" alt="">
            </div>
          </div>
          <div class="info-description">
            <p><br></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p> Cordobés & Andresito son dos jóvenes DJ's y productores procedentes de Navalcán, pueblo de la comarca de
              Talavera de la Reina (Toledo), que empezaron su andadura hace más de {{ yearsActive }} años.</p>
            <p>Su música ha sonado por diferentes pueblos de la misma comarca, no obstante son residentes en varios
              locales de su pueblo natal.</p>
            <p>Les caracteriza una música única del ámbito urbano aunque su especialidad es el reggaeton antiguo y los
              auténticos mashup que tanto gustan.</p>
            <p>En esta web podrás visualizar todo su contenido.</p>
          </div>
        </div>
        <div class="info-contact" id="contacto">
          <p><a href="https://www.instagram.com/djc.dja/" target="_blank" rel="noopener">Instagram</a> / <a
              href="https://www.facebook.com/djcordobesdjandresito/" target="_blank" rel="noopener">Facebook</a> / <a
              href="https://soundcloud.com/djcordobesandresito/tracks" target="_blank" rel="noopener">Soundcloud</a> / <a
              href="https://linktr.ee/cordobesandresito" target="_blank" rel="noopener">Otros enlaces</a>
          </p>
          <p>Booking: <a href="mailto:cordobesandresito@gmail.com" target="_blank" rel="noreferrer noopener">cordobesandresito@gmail.com</a></p>

          <p><a href="https://drive.google.com/drive/folders/1e5vqjVheFjkSj6TVbU970a6Mji96pePb" target="_blank" rel="noreferrer noopener">Recursos para salas</a></p>

          <p>MashupHub <a href="https://mashuphub.net/" target="_blank" rel="noreferrer noopener">Comparte tu música aquí!</a></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import {useStore} from "vuex";
const store = useStore();
import {computed} from "vue";

let activeSection = ref('bio');

const currentUrl = computed(() => store.getters.getCurrentUrlWeb);

const sections = [
  { type: 'bio', label: 'Bio' },
  { type: 'contacto', label: 'Contacto' }
];

const currentYear = new Date().getFullYear();
const yearsSinceStart = currentYear - 2018;
const yearsActive = ref(yearsSinceStart);

const handleSection = (type) => {
  activeSection.value = type;
  smoothScrollToSection(type);
};

const smoothScrollToSection = (sectionId) => {
  const sectionElement = document.getElementById(sectionId);

  if (sectionElement) {
    sectionElement.scrollIntoView({
      behavior: 'smooth',
    });
  }
};

const handleScroll = (event) => {
  const scrollPosition = event.target.scrollTop;

  sections.forEach((section) => {
    const sectionElement = document.getElementById(section.type);

    if (sectionElement) {
      const sectionTop = sectionElement.offsetTop;
      const sectionHeight = sectionElement.clientHeight;

      if (scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight) {
        activeSection.value = section.type;
      }
    }
  });
};
</script>

<style scoped>
/* nav */
.info-menu {
  align-items: center;
  display: flex;
  font-size: 0;
  left: 50%;
  position: absolute;
  top: 6rem;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 3;
  opacity: 1;
  transition: opacity .4s ease .4s, visibility .4s ease .4s;
  visibility: visible;
}

@media (max-width: 768px) {
  .info-menu {
    top: 3rem
  }
}

.info-menu-divider {
  display: inline-block;
  font-size: 3rem;
  opacity: .5;
  vertical-align: middle
}

@media (max-width: 768px) {
  .info-menu-divider {
    font-size: 2.5rem
  }
}

.info-menu-button {
  font-size: 3rem;
  opacity: .5;
  transition: opacity .4s ease;
  vertical-align: middle
}

.info-menu-button:hover {
  opacity: .25
}

.info-menu-button--active, .info-menu-button--active:hover {
  opacity: 1;
}

@media (max-width: 768px) {
  .info-menu-button {
    font-size: 3rem
  }
}

.info-container {
  opacity: 1;
  pointer-events: auto;
  transition: opacity .3s ease .3s, visibility .3s ease .3s;
  visibility: visible;
  z-index: 3;
}

.info-wrapper {
  overflow-y: scroll;
  height: 100%;
}

@media (max-width: 768px) {
  .info:after, .info:before {
    display: block;
    height: 10rem
  }
}

.info-menu {
  opacity: 1;
  transition: opacity .4s ease .4s, visibility .4s ease .4s;
  visibility: visible
}

.info-media {
  height: 67.2rem;
  margin: 0 auto 6.5rem;
  position: relative;
  width: 48rem
}

@media (max-width: 768px) {
  .info-media {
    height: 55rem;
    margin-bottom: 3.5rem;
    width: 100%
  }
}

.info-media-image {
  opacity: 0 !important
}

.info-media-image--active {
  opacity: 1 !important
}

.info-content {
  position: relative
}

.info-content p {
  line-height: 1.2
}

.info-content p:not(:first-child) {
  margin-top: 4rem
}

.info-content a {
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top
}

.info-content a:before {
  background: currentColor;
  bottom: 0;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  transition: transform .7s cubic-bezier(.19,1,.22,1);
  width: 100%
}

.info-content a:before {
  transform: scaleX(1);
  transform-origin: left center
}

.info-content a:focus:before,.info-content a:hover:before {
  transform: scaleX(0);
  transform-origin: right center
}

@media (max-width: 768px) {
  .info-content {
    margin: auto;
    max-width: 42rem;
    padding: 0
  }

  .info-content p:not(:first-child) {
    margin-top: 2rem
  }
}

.info-box {
  margin: auto;
  max-width: 90rem;
  padding: 14rem 0 0 0;
}

@media (max-width: 768px) {
  .info-box {
    font-size: 2.5rem;
    padding: 10rem 0
  }
}

.info-contact {
  min-height: 75vh;
  margin: auto;
  max-width: 90rem;
  padding: 16rem 0
}

@media (max-width: 768px) {
  .info-contact {
    font-size: 3rem;
    text-align: center;
    padding: 0 0 15rem 0;
    min-height: 0;
  }

  .info-contact a {
    display: block
  }

  .info-contact a:not(:last-child) {
    margin-bottom: 2rem
  }

  .info-contact a:before, .info-contact br {
    display: none
  }

  .info-contact p:first-child {
    font-size: 0
  }

  .info-contact p:first-child a {
    font-size: 3rem
  }

  .info-contact p:not(:first-child) {
    margin-top: 7rem
  }
}

.info-credits {
  bottom: 12rem;
  font-size: 2.4rem;
  line-height: 2.8rem;
  position: absolute;
  left: 4.5rem;
  transform: rotate(-90deg) translateY(150%);
  transform-origin: bottom left;
  transition: opacity .4s ease;
  white-space: nowrap;
  z-index: 2
}

.info-credits:hover {
  opacity: .5
}

@media (max-width: 768px) {
  .info-credits {
    display: none
  }
}
</style>