<template>
  <nav class="navigation">
    <a class="navigation-logo" @click="handleClick">
      Cordobes & Andresito
    </a>
    <button class="navigation-toggle" @click="handleClick">Menu</button>
    <ul class="navigation-list">
      <li class="navigation-list-item">
        <router-link to="/" @click="handleClick">
          <a class="navigation-button">
            <svg class="navigation-button-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.67 19.67">
              <path stroke="#fff" stroke-width="3.5" d="M1.24,18.43l17.2-17.2"></path>
              <path stroke="#fff" stroke-width="3.5" d="M1.24,1.24l12.81,12.81l4.27,4.27"></path>
            </svg>
            <span class="navigation-button-text">Home</span>
          </a>
        </router-link>
      </li>
      <li class="navigation-list-item">
        <router-link to="/music" @click="handleClick">
          <a class="navigation-list-link">Música</a>
        </router-link>
      </li>
      <li class="navigation-list-item">
        <router-link to="/tour" @click="handleClick">
          <a class="navigation-list-link">Tour</a>
        </router-link>
      </li>
      <li class="navigation-list-item">
        <router-link to="/videos" @click="handleClick">
          <a class="navigation-list-link">Videos</a>
        </router-link>
      </li>
      <li class="navigation-list-item">
        <router-link to="/galeria" @click="handleClick">
          <a class="navigation-list-link">Galería</a>
        </router-link>
      </li>
      <li class="navigation-list-item">
        <router-link to="/newsletter" @click="handleClick">
          <a class="navigation-list-link">Newsletter</a>
        </router-link>
      </li>
      <li class="navigation-list-item">
        <router-link to="/info" @click="handleClick">
          <a class="navigation-list-link">Info</a>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script setup>
import { ref } from 'vue';

const isMobile = ref(window.innerWidth <= 768);

// Añadir un escucha de evento de cambio de tamaño de ventana
window.addEventListener('resize', () => {
  isMobile.value = window.innerWidth <= 768;
});

// Definir la función toggleNavigation
const toggleNavigation = () => {
  const htmlElement = document.documentElement;
  htmlElement.classList.toggle('navigation--open');
}

// Lógica para ejecutar la función solo en móviles
const handleClick = () => {
  if (isMobile.value) {
    toggleNavigation();
  }
}
</script>

<style scoped>
.navigation--hidden:not(.home--moving) .cursor {
  opacity: 0
}

.logo--active, .navigation--open .logo {
  opacity: 1;
  transform: translate(-50%);
  visibility: visible
}

.navigation--hidden .logo {
  opacity: 0;
  transform: translate(-50%, -4rem);
  transition: opacity .3s ease, transform .3s ease, visibility .3s ease;
  visibility: hidden
}

.navigation {
  pointer-events: none;
  transition: opacity .3s ease .3s;
  z-index: 5
}

.preload .navigation {
  opacity: 0
}

@media (max-width: 768px) {
  .navigation--open .navigation {
    pointer-events: auto
  }
}

.navigation-logo {
  margin: 0 auto;
  color: #fff;
  display: none;
  text-align: center;
  position: absolute;
  opacity: 0;
  text-transform: uppercase;
  top: 6rem;
  transition: opacity .4s ease, visibility .4s ease;
  transform: translateX(41%);
  visibility: hidden;
  z-index: 1;
}

@media (max-width: 768px) {
  .navigation-logo {
    display: block;
    top: 3.4rem
  }

  .navigation--open .navigation-logo {
    opacity: 1;
    visibility: visible
  }
}

.navigation-logo-text {
  width: 24.1rem
}

.navigation-list {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  letter-spacing: .1rem;
  line-height: 1;
  padding: 4.5rem 6rem;
  position: absolute;
  text-transform: uppercase;
  width: 100%
}

@media (max-width: 768px) {
  .navigation-list {
    flex-direction: column;
    font-size: 2.5rem;
    height: 100%;
    justify-content: space-evenly;
    padding: 6rem 0
  }

  .navigation-list:before {
    background: #000;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transform-origin: bottom;
    transition: opacity .3s ease .3s;
    width: 100%
  }

  .navigation--open .navigation-list:before {
    opacity: 1;
    transition: opacity .3s ease
  }
}

.navigation-list-item {
  display: inline-block;
  position: relative;
  transition: opacity .4s ease .4s, transform .4s ease .4s;
  vertical-align: middle;
  z-index: 1
}

@media (min-width: 769px) {
  .navigation--hidden .navigation-list-item:not(:first-child) {
    opacity: 0;
    transform: translateY(4rem);
    transition: opacity .4s ease, transform .4s ease
  }
}

@media (max-width: 768px) {
  .navigation-list-item {
    opacity: 0;
    transition: opacity .3s ease, visibility .3s ease;
    visibility: hidden
  }

  .navigation--open .navigation-list-item {
    opacity: 1;
    transition: opacity .3s ease .3s, visibility .3s ease .3s;
    visibility: visible
  }
}

.navigation-list-link {
  display: block;
  pointer-events: auto;
  transition: opacity .4s ease
}

.navigation-list-link:hover {
  opacity: .5
}

.navigation-button {
  display: block;
  font-size: 0;
  height: 2.6rem;
  pointer-events: auto;
  position: relative;
  transition: opacity .4s ease, transform .4s ease;
  width: 2.6rem
}

.navigation--hidden .navigation-button {
  opacity: 0
}

.home--moving .navigation-button, .navigation--open .navigation-button {
  opacity: 1
}

.navigation-button:hover {
  opacity: .5
}

@media (max-width: 768px) {
  .navigation-button {
    height: auto;
    width: auto
  }
}

.navigation-button-text {
  bottom: 100%;
  font-size: 2.4rem;
  letter-spacing: .1rem;
  left: 0;
  opacity: 0;
  padding-left: 5rem;
  position: absolute;
  text-transform: uppercase;
  transform: rotate(-90deg) translateY(100%);
  transform-origin: left bottom;
  transition: opacity .4s ease;
  white-space: nowrap
}

.home--active.home--moving:not(.navigation--hidden) .navigation-button-text {
  opacity: 1
}

@media (min-width: 769px) {
  .home--active:not(.navigation--hidden) .navigation-button:hover .navigation-button-text {
    opacity: .5
  }
}

@media (max-width: 768px) {
  .navigation-button-text {
    opacity: 1;
    padding: 0;
    position: relative;
    transform: none
  }
}

.navigation-button-icon {
  transform: rotate(45deg);
  transition: opacity .4s ease, transform .4s ease
}

.navigation--hidden .navigation-button-icon {
  transform: rotate(0)
}

@media (max-width: 768px) {
  .navigation-button-icon {
    display: none
  }
}

.navigation-toggle {
  bottom: 3.4rem;
  display: none;
  left: 50%;
  pointer-events: auto;
  position: absolute;
  text-transform: uppercase;
  transform: translateX(-50%);
  transition: opacity .4s ease;
  z-index: 2
}

@media (max-width: 768px) {
  .navigation-toggle {
    display: block
  }

  .navigation--hidden .navigation-toggle {
    opacity: 0
  }

  .home--moving .navigation-toggle, .navigation--open .navigation-toggle {
    opacity: 1
  }
}

</style>