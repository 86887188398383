// router/index.js
import {createRouter, createWebHistory} from 'vue-router';
import store from '@/store';
import HomeComponent from '@/components/Home/HomeComponent.vue';
import MusicComponent from '@/components/Music/MusicComponent.vue';
import TourComponent from "@/components/Tour/TourComponent.vue";
import VideoComponent from "@/components/Videos/VideoComponent.vue";
import GaleriaComponent from "@/components/Galeria/GaleriaComponent.vue";
import InfoComponent from "@/components/Info/InfoComponent.vue";
import NewsletterComponent from "@/components/Newsletter/NewsletterComponent.vue";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {path: '/', component: HomeComponent, name: 'HomeComponent'},
        {path: '/music', component: MusicComponent, name: 'MusicComponent'},
        {path: '/tour', component: TourComponent, name: 'TourComponent'},
        {path: '/videos', component: VideoComponent, name: 'VideoComponent'},
        {path: '/galeria', component: GaleriaComponent, name: 'GaleriaComponent'},
        {path: '/newsletter', component: NewsletterComponent, name: 'NewsletterComponent'},
        {path: '/info', component: InfoComponent, name: 'InfoComponent'},

        //control para cualquier ruta con error o que no exista vaya a la home
        { path: '/:pathMatch(.*)', redirect: '/' }
    ],
});

router.beforeEach((to, from, next) => {
    store.commit('setCurrentComponentName', to.name);
    next();
});

export default router;