<template>
  <div :class="{ 'tour': true, 'tour-container': true, 'tour-active': true }">
    <nav class="tour-menu">
      <button class="tour-menu-button tour-menu-button--active" value="tour">
        Tour Cordobés & Andresito
      </button>
    </nav>

    <div class="tour-wrapper">
      <div class="tour-list">
        <div v-for="event in events" :key="event.id" class="tour-list-item tour-list-item--visible">
          <div class="tour-list-item-header">
            <!-- año -->
            <p class="tour-list-item-year">{{ getYear(event.start.dateTime) }}</p>

            <!-- mes y dia -->
            <p class="tour-list-item-date">{{ getFormattedDate(event.start.dateTime) }}</p>

            <div v-if="event.location" class="tour-list-item-location">
              <p>{{ formatLocation(event.location) }}</p>
            </div>
            <div class="tour-list-item-title">
              <a v-if="event.description" class="tour-list-item-title-link" :href="formatHref(event.description)"
                 rel="noopener"
                 target="_blank">{{
                  event.summary
                }}
                <span v-if="formatHref(event.description) !== null"></span></a>

              <a v-else class="tour-list-item-title-link" rel="noopener"
                 target="_blank">{{
                  event.summary
                }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import axios from 'axios';

const events = ref([]);

const getEvents = async () => {
  try {
    const response = await axios.get('/api/events', {
      headers: {
        'X-API-Key': process.env.VUE_APP_API_KEY
      }
    });

    const unsortedEvents = response.data;

    const beforeYesterday = new Date();
    beforeYesterday.setDate(beforeYesterday.getDate() - 200);

    // Ordenar eventos si vienen mal
    events.value = unsortedEvents
        .filter(event => {
          const eventDate = new Date(event.start.dateTime);
          // Comprueba si la fecha del evento es mayor que la fecha de mañana
          return eventDate > beforeYesterday;
        })
        .sort((a, b) => {
          const dateA = new Date(a.start.dateTime);
          const dateB = new Date(b.start.dateTime);
          return dateA - dateB;
        });
  } catch (error) {
    console.error('Error fetching events:', error);
  }
};

const getYear = (dateTime) => new Date(dateTime).getFullYear();

const getFormattedDate = (dateTime) => {
  const options = {month: 'short', day: '2-digit'};
  return new Date(dateTime).toLocaleDateString('en-US', options);
};

const formatLocation = (location) => {
  const parts = location.split(',').map((part) => part.trim());

  if (parts.length > 1) {
    const [city, ...rest] = parts;

    if (rest.length > 2) {
      return `${city} (${rest[1]})`;
    } else {
      return `${city}`;
    }
  } else {
    return location;
  }
};

const formatHref = (description) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(description, 'text/html');
  const anchorElement = doc.querySelector('a');

  return anchorElement ? anchorElement.getAttribute('href') : description;
};

onMounted(() => {
  getEvents();
});

</script>

<style scoped>
/* nav */
.tour-menu {
  align-items: center;
  display: flex;
  font-size: 0;
  left: 50%;
  position: absolute;
  top: 6rem;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 3;
  opacity: 1;
  transition: opacity .4s ease .4s, visibility .4s ease .4s;
  visibility: visible;
}

@media (max-width: 768px) {
  .tour-menu {
    top: 4rem
  }
}

.tour-wrapper {
  overflow-y: scroll;
  height: 100%;
}

.tour-menu-divider {
  display: inline-block;
  font-size: 3rem;
  opacity: .5;
  vertical-align: middle
}

@media (max-width: 768px) {
  .tour-menu-divider {
    font-size: 2.5rem
  }
}

.tour-menu-button {
  font-size: 3rem;
  opacity: .5;
  transition: opacity .4s ease;
  vertical-align: middle
}

.tour-menu-button:hover {
  opacity: .25
}

.tour-menu-button--active, .tour-menu-button--active:hover {
  opacity: 1;
}

@media (max-width: 768px) {
  .tour-menu-button {
    font-size: 3rem
  }
}

.tour-container {
  opacity: 1;
  pointer-events: auto;
  transition: opacity .3s ease .3s, visibility .3s ease .3s;
  visibility: visible;
  z-index: 3;
}

.tour-list {
  margin: auto;
  padding-bottom: 28rem;
  padding-top: 16rem;
  width: 120rem
}

@media (max-width: 768px) {
  .tour-list {
    font-size: 2.3rem;
    padding-bottom: 16rem;
    width: 42rem
  }
}

.tour-list-item {
  opacity: 0
}

.tour-list-item--visible {
  opacity: 1;
  transition: opacity .4s ease
}

.tour-list-item:not(:first-child) {
  margin-top: 5rem
}

.tour-list-item-header {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 7.6rem;
  transition: opacity .4s ease
}

.tour-list-item-header:hover {
  opacity: .5
}

.tour-list-item--active .tour-list-item-header:hover {
  opacity: 1
}

@media (max-width: 768px) {
  .tour-list-item-header {
    min-height: auto
  }
}

.tour-list-item-year {
  width: 12rem
}

@media (max-width: 768px) {
  .tour-list-item-year {
    width: 9rem
  }
}

.tour-list-item-date {
  width: 12rem
}

@media (max-width: 768px) {
  .tour-list-item-date {
    width: 9rem
  }
}

.tour-list-item-location {
  width: 36rem
}

@media (max-width: 768px) {
  .tour-list-item-location {
    text-align: right;
    width: calc(100% - 18rem)
  }
}

.tour-list-item-title {
  text-align: right;
  width: calc(100% - 76.5rem)
}

@media (max-width: 768px) {
  .tour-list-item-title {
    padding-left: 20rem;
    width: 100%
  }
}

.tour-list-item-title-link span:last-child {
  white-space: nowrap
}

.tour-list-item-title-link span:last-child:after {
  background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="26.53" width="26.53"><path fill="%23fff" d="M26.11 11.1l-1.79-.11-.1-7.11L1.58 26.53 0 24.96 22.65 2.31l-7.11-.1-.1-1.79L26.53 0l-.42 11.1z"/></svg>') no-repeat 50%/cover;
  content: "";
  display: inline-block;
  height: 2.4rem;
  margin-left: .5rem;
  margin-top: -.6rem;
  vertical-align: middle;
  width: 2.4rem
}

@media (max-width: 768px) {
  .tour-list-item-title-link span:last-child:after {
    height: 1.4rem;
    margin-left: .25rem;
    margin-top: -.2rem;
    width: 1.4rem
  }
}

.tour-list-item-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height .4s ease .4s
}

.tour-list-item--active .tour-list-item-content {
  transition: max-height .4s ease
}

.tour-list-item-content-wrapper {
  padding-bottom: 5rem;
  padding-top: 5rem
}

.tour-list-item-media {
  display: block;
  font-size: 0;
  margin: auto;
  max-width: 56.2rem;
  opacity: 0;
  position: relative;
  transition: opacity .4s ease
}

.tour-list-item--active .tour-list-item-media {
  opacity: 1;
  transition: opacity .4s ease .4s
}

.tour-list-item-media-ratio {
  display: inline-block;
  width: 100%
}

.tour-list-loading {
  display: block;
  text-align: center
}

.tour-footer {
  opacity: 1;
  position: absolute;
  right: 6rem;
  top: 6rem;
  visibility: visible;
  z-index: 3
}

@media (max-width: 768px) {
  .tour-footer {
    right: 3rem;
    top: 4rem
  }
}
</style>
