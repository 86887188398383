<template>
  <div class="carousel-container">
    <swiper
        :spaceBetween="30"
        :centeredSlides="true"
        :autoplay="{
        delay: 8000,
        disableOnInteraction: false,
      }"
        :effect="'fade'"
        :navigation="false"
        :modules="modules"
        class="mySwiper"
    >
      <swiper-slide v-for="(image, index) in selectedImages" :key="index">
        <img :src="currentUrl + image.src" :alt="image.alt"/>
      </swiper-slide>
    </swiper>

    <!-- capa fondo -->
    <div class="carousel-overlay"></div>
  </div>
</template>

<script setup>
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {Autoplay, EffectFade, Navigation, Pagination} from 'swiper/modules';
import { useStore } from 'vuex';
import { ref, onMounted, onUnmounted, computed } from 'vue';

const modules = [Autoplay, EffectFade, Pagination, Navigation];
const store = useStore();
const currentUrl = computed(() => store.getters.getCurrentUrlWeb);

const isMobile = ref(false);

const horizontalImages = [
  {
    src: 'home11.jpg',
    alt: 'Slide 3'
  },
  {
    src: 'home7.jpg',
    alt: 'Slide 3'
  },
  {
    src: 'home6.jpg',
    alt: 'Slide 3'
  },
  {
    src: 'home8.jpg',
    alt: 'Slide 3'
  },
  {
    src: 'home2.jpg',
    alt: 'Slide 3'
  },
  {
    src: 'home9.jpg',
    alt: 'Slide 3'
  },
  {
    src: 'home3.jpg',
    alt: 'Slide 3'
  },
  {
    src: 'home4.jpg',
    alt: 'Slide 3'
  },
  {
    src: 'home5.jpg',
    alt: 'Slide 3'
  },
  {
    src: 'home10.jpg',
    alt: 'Slide 3'
  },
  {
    src: '/huerto/huerto10.jpg',
    alt: 'Slide 3'
  }
];

const verticalImages = [
  {
    src: 'bio.jpg',
    alt: 'Slide 1'
  },
  {
    src: '/navalcan24/Cordobes y Andresito-2.jpg',
    alt: 'Slide 3'
  },
  {
    src: 'home1.jpg',
    alt: 'Slide 3'
  },
  {
    src: 'M1-1.jpg',
    alt: 'Slide 2'
  },
  {
    src: '/gredos/7.jpg',
    alt: 'Slide 3'
  },
  {
    src: '/lake2/6.jpg',
    alt: 'Slide 3'
  },
  {
    src: 'M1-3.jpg',
    alt: 'Slide 3'
  },
  {
    src: '/navalcan24/Cordobes y Andresito-3.jpg',
    alt: 'Slide 3'
  },
  {
    src: '/gredos/14.jpg',
    alt: 'Slide 3'
  },
  {
    src: '/huerto/huerto1.jpg',
    alt: 'Slide 3'
  },
  {
    src: '/huerto/huerto9.jpg',
    alt: 'Slide 3'
  },
  {
    src: '/lake2/8.jpg',
    alt: 'Slide 3'
  },
  {
    src: '/navalcan24/Cordobes y Andresito.jpg',
    alt: 'Slide 3'
  },
  {
    src: 'M1-4.jpg',
    alt: 'Slide 4'
  }
];

const handleResize = () => {
  isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
  window.addEventListener('resize', handleResize);
  handleResize();
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});

const selectedImages = computed(() => {
  return isMobile.value ? verticalImages : horizontalImages;
});
</script>

<style>
.carousel-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.carousel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.49);
  z-index: 1;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
