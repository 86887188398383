<template>
  <div @mousemove="handleMouseMove" class="home" style="display: contents;">
    <Carousel/>
  </div>
</template>

<script>
import Carousel from './Carousel/CarouselComponent.vue';

export default {
  name: 'HomeComponent',
  components: {
    Carousel,
  },
  created() {
    this.activateHome()
  },
  methods: {
    activateHome() {
      // Añade la clase home--active al <html> al cargar el componente
      document.documentElement.classList.add('home--active');
    },

    handleMouseMove() {
      // Elimina la clase home--moving si existe un temporizador pendiente
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }

      // Añade la clase home--moving al <html> al mover el cursor
      document.documentElement.classList.add('home--moving');

      // Establece un temporizador para quitar la clase después de 2 segundos
      this.timeoutId = setTimeout(() => {
        document.documentElement.classList.remove('home--moving');
        this.timeoutId = null; // Restablece el identificador del temporizador
      }, 2000);
    }
  }
};
</script>

<style>
.home {
  opacity: 1;
  pointer-events: auto;
  transition: opacity .3s ease .3s, visibility .3s ease .3s;
  visibility: visible;
  z-index: 3;
}
</style>
